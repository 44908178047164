/* src/styles/Content.css */
  
/*ABOUT*/

.content {
    margin-left: 40%;
    color: #7c7c7c;
    width: 50%;
    right: 0;
    /* padding: 3vh; */
    z-index: 1;
  }
  
  .content section {
    margin-bottom: 7.5vh;
  }
  
  h2 {
    color: var(--main-color);
  }
  
  p {
   color: var(--text-color);
    text-align: justify;
    font-size: 19px;
  }

  .content section p:first-of-type {
    padding-top: 15vh;
  }
  
  .content section p:not(:first-of-type) {
    padding-top: 4vh;
  }
   
/*WORK - PROJECTS*/

#work-projects {
  /* padding: 2vh 2vh; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.projects-container {
  display: flex;
  align-items: flex-start;
  /* padding-left: 1rem; */
  justify-content: space-between;
  border-radius: 2px solid; 
}

.left-column {
  width: 30%;
}

.right-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.date-header {
  font-weight: bold;
  color: #9e9e9e;
  min-width: 170px;
  font-size: 14px;
  padding: 0.5vh;
  text-align: left;
}

.date-header:hover {
  padding: 0.5vh;
}

.work-text {
  font-size: 19px;
  color: #6b6666;
  flex-grow: 1; 
  width: 100%;
}

.experience-title-11n {
  position: relative;
  text-align: left;
  font-weight: bold; 
  font-size: 18px;
  color: #7c7c7c;
  margin-top: 0;
}

.experience-container:hover {
  box-shadow: 0 0 10px rgbargba(0, 0, 0, 0.3);
  border: 2px solid #777ab6;
  border-radius: 5px;
  background-color: rgba(17, 34, 64, 0.35); 
  box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.9);
}

/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    border-radius: 5px; */

.experience-text {
  font-weight: normal;
  font-size: 16px;
  width: 90%;
  color: #7c7c7c;
  text-align: justify;
}

.job-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5vh
}

.experience-wrapper {
  display: flex;
  flex-direction: column; 
}

.experience-container {
  display: flex;
  flex-direction: column;
  transition: border,box-shadow 0.5s;
  /* padding: 1vh; */
  border-radius: 5%;
  background-color: #050214; 
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7)
}

.experience-content {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.skill-bubbles {
  position: relative;
  border: 2px solid #7487f1;
  border-radius: 9999px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #777ab6;
  display: inline-block;
  padding: 0.75vh 1.75vh;
  width: auto;
  margin-right: 1vh;
  margin-top: 1vh;
  font-weight: bold;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
}

/*CONTACT*/

#contact-form {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  padding-right: 1rem;
}

#contact-form div {
  display: flex;
  flex-direction: column;
  text-align: left;
}

#contact-form label {
  margin-bottom: 0.5rem;
}

#contact-form input,
#contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
}

#contact-form textarea {
  margin-bottom: 1rem;
}

#contact-form button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #7487f1;
  color: white;
  cursor: pointer;
}

#contact-form button:hover {
  background-color: #5563b1;
}

/*PHONE*/

@media (max-width: 768px) {
  .content {
      margin-left: 0;
      padding: 20px;
      width: 100%;
  }

  .content section p:first-of-type {
    padding-top: 0vh;
  }

  .projects-container {
      flex-direction: column;
  }

  .right-column {
      width: 100%;
  }

  .experience-text {
    font-weight: normal;
    font-size: 16px;
    width: 100%;
    color: #7c7c7c;
    text-align: justify;
  }

  .experience-content {
      flex-direction: column;
  }

  .job-info {
      padding: 10px 0;
  }

  #contact-form {
      display: flex;
      flex-direction: column;
      padding: 0px;
  }

  #contact-form div {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  }

  #contact-form label {
      margin-bottom: 5px;
  }

  #contact-form input,
  #contact-form textarea {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-align: left;
      width: 100%; 
      box-sizing: border-box;
  }

  #contact-form textarea {
      margin-bottom: 10px;
      height: 100px;
  }

  #contact-form button {
      padding: 10px;
      border: none;
      border-radius: 4px;
      background-color: #7487f1;
      color: white;
      cursor: pointer;
      margin-top: 10px;
  }

  #contact-form button:hover {
      background-color: #5563b1;
  }
}